<template>
  <!-- Details -->
  <b-row v-if="showreport">
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="editAtt"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Edit Attendace</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideAtt()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1" style="align-items: center">
          <b-col md="3">
            <b-img
              v-if="attendance.picture == null || attendance.picture == ''"
              src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
              style="width: 100px; height: 100px; object-fit: cover"
            />
            <b-img
              v-else
              :src="attendance.picture"
              style="
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 50%;
              "
            />
          </b-col>
          <b-col md="8" class="ml-1">
            <h5>Name: {{ attendance.name }}</h5>
            <h5>Father Name: {{ attendance.father_name }}</h5>
            <h5>Gr# {{ attendance.grNo }}</h5>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Time"
              invalid-feedback="time is required."
              ref="time"
            >
              <flat-pickr
                v-model="timePicker"
                class="form-control"
                :config="{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'h:i:K',
                }"
                @input="setTime()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="date is required."
              ref="date"
            >
              <flat-pickr
                ref="date"
                :config="config2"
                v-model="datepickr"
                class="form-control"
                placeholder="Select Date."
                @input="setTime()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Select Status"
              invalid-feedback="status is required."
              ref="status"
            >
              <v-select
                ref="status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="attendance.obj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              class="mr-1 fullWidth"
              @click="saveAttendance()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Send Messsage</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1" style="align-items: center">
          <b-col md="12">
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="msg_class"
            >
              <v-select
                ref="msg_class"
                multiple
                :closeOnSelect="false"
                v-model="msgClass"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillMsgSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="msgClass[0] != 0">
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="msg_section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="msgSection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="msgSecOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkMsgSections()"
                ref="msg_section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="msg_date"
            >
              <flat-pickr
                ref="msg_date"
                :config="config"
                v-model="msgObj.dt"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Select Status"
              invalid-feedback="status is required."
              ref="msg_status"
            >
              <v-select
                ref="msg_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="msgObj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveMessage()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Send Message</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Reports</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1" style="align-items: center">
          <b-col md="12">
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="rep_class"
            >
              <v-select
                ref="rep_class"
                multiple
                :closeOnSelect="false"
                v-model="repClass"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillRepSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="repClass[0] != 0">
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="rep_section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="repSection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="repSecOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkRepSections()"
                ref="rep_section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="rep_date"
            >
              <flat-pickr
                ref="rep_date"
                :config="config2"
                v-model="repDate"
                class="form-control"
                placeholder="Select Date"
                @on-change="setRepDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Select Status"
              invalid-feedback="status is required."
              ref="rep_status"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                ref="rep_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="repObj.status"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
                @input="setStatus()"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="getReport()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Show</span>
            </b-button>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="getSheet()"
              :disabled="sheetLoading"
              block
            >
              <b-spinner v-if="sheetLoading" small type="grow" />
              <span v-else>Attendance Sheet</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Smart Attendance</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1">
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="msg_date"
            >
              <flat-pickr
                ref="msg_date"
                :config="config"
                v-model="smartObj.dt"
                class="form-control"
                placeholder="Select Date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Gr Numbers"
              invalid-feedback="Please enter gr separated by space"
              ref="grs"
            >
              <b-form-textarea
                ref="grs"
                placeholder="Enter gr numbers seperated by space"
                v-model="smartObj.gr"
                rows="4"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group
              label="Status"
              invalid-feedback="Status is required."
              ref="sm_status"
            >
              <v-select
                ref="sm_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="smartObj.status"
                :options="smartOptions"
                :reduce="(opt) => opt.value"
                label="name"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveSmart()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-col>
      <div class="d-flex justify-content-between">
        <b-button
          variant="outline-secondary"
          class="mr-50"
          @click="showreport = false"
        >
          <feather-icon class="" icon="ArrowLeftIcon" />
          Back
        </b-button>
        <!-- <div>
          <b-button
            class="mr-50"
            v-for="t in types"
            :key="type"
            :variant="t.value == report.type ? 'primary' : 'outline-primary'"
            @click="setType(t.value)"
          >
            {{ t.name }}
          </b-button>
        </div> -->
      </div>
      <b-card class="mt-1">
        <b-row style="align-items: center">
          <!-- <b-col md="2">
            <b-form-group
              label="Select type"
              invalid-feedback="type is required."
              ref="type"
            >
              <v-select
                ref="type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="report.type"
                :options="types"
                :reduce="(opt) => opt.value"
                label="name"
                @input="setType()"
                placeholder="select type"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="2" v-if="showClasses">
            <b-form-group
              label="Select Class"
              invalid-feedback="class is required."
              ref="class"
            >
              <v-select
                ref="class"
                multiple
                :closeOnSelect="false"
                v-model="classID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="2" v-if="showClasses">
            <b-form-group
              label="Select Section"
              invalid-feedback="section is required."
              ref="section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="secID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="admittedSections"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkSections()"
                ref="section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Select Subject"
              invalid-feedback="Subject is required."
              ref="sub"
            >
              <!-- multiple
            :closeOnSelect="false" -->
              <v-select
                v-model="subID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subjects"
                :reduce="(val) => val.id"
                :clearable="false"
                ref="sub"
                label="subject"
                placeholder="Select subject"
                @input="loadStaff()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Teacher"
              invalid-feedback="Teacher is required."
              ref="staff"
            >
              <v-select
                v-model="tID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="staffList"
                :reduce="(opt) => opt.id"
                label="name"
                :clearable="false"
                placeholder="Select teacher"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Date"
              invalid-feedback=" date is required."
              ref="date"
            >
              <flat-pickr
                ref="date"
                :config="config"
                v-model="report.dtFrom"
                class="form-control"
                placeholder="Select Date."
              />
              <!-- @input="setDate()" -->
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              class="ml-1"
              :disabled="gridLoading"
              variant="primary"
              @click="loadgrid()"
            >
              Show
            </b-button>
            <b-button
              variant="success"
              class="ml-1"
              :disabled="saving"
              @click="save()"
            >
              Save
            </b-button>
          </b-col>
          <b-col md="12" class="text-right">
            <b-button
              class="btn-icon"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Smart Attendance"
              @click="showSmart()"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
            <b-button
              class="ml-1 btn-icon"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Reports"
              @click="openReport()"
            >
              <feather-icon icon="BarChart2Icon" />
            </b-button>
            <b-button
              class="ml-1 btn-icon"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Messages"
              @click="openMessage()"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1" v-if="showGrid">
          <b-col lg="10" md="9">
            <b-form-group label="Search">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Search by name or gr"
              />
            </b-form-group>
          </b-col>
          <b-col lg="2" md="3">
            <b-form-group label="Sort By">
              <v-select
                v-model="sortBy"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sortOptions"
                :reduce="(val) => val.value"
                label="text"
                @input="sortData()"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-table
              class="mt-1"
              :busy="gridLoading"
              show-empty
              :items="filteredAtt"
              :fields="fields"
              responsive
              hover
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>

              <template #cell(cls)="data">
                <b-badge style="margin-inline: 2px" variant="light-primary">
                  {{ data.item.cls }}
                </b-badge>
              </template>

              <!-- <template #cell(profile)="data">
                <b-avatar
                  size="36"
                  class=""
                  variant="primary"
                  :src="data.item.picture"
                />
              </template> -->
              <template #cell(date)="data">
                <b-badge variant="light-primary">
                  {{
                    new Date(data.item.date).toLocaleDateString("en-UK", {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    })
                  }}
                </b-badge>
                <b-badge variant="light-primary">
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(data.item.date))
                  }}
                </b-badge>
              </template>
              <template #cell(status)="data">
                <div class="d-flex align-items-center">
                  <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status == 'P'
                        ? 'success'
                        : 'outline-success'
                    "
                    @click="changeAtt(data.index, 'P')"
                  >
                    P
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status == 'A' ? 'danger' : 'outline-danger'
                    "
                    @click="changeAtt(data.index, 'A')"
                  >
                    A
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status == 'L' ? 'info' : 'outline-info'
                    "
                    @click="changeAtt(data.index, 'L')"
                  >
                    L
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status.toLowerCase() == 'late'
                        ? 'warning'
                        : 'outline-warning'
                    "
                    @click="changeAtt(data.index, 'Late')"
                  >
                    Late
                  </b-button>
                  <!-- <b-button
                    pill
                    size="sm"
                    style="margin-inline: 2px"
                    :variant="
                      data.item.obj.status == 'H' ? 'info' : 'outline-info'
                    "
                    @click="changeAtt(data.index, 'H')"
                  >
                    H
                  </b-button> -->
                </div>
                <!-- <b-badge v-if="data.item.status == 'P'" variant="light-success">
                {{ data.item.status }}
              </b-badge>
              <b-badge
                v-else-if="data.item.status == 'L'"
                variant="light-warning"
              >
                {{ data.item.status }}
              </b-badge>
              <b-badge v-else variant="light-danger">
                {{ data.item.status }}
              </b-badge> -->
              </template>
              <template #cell(actions)="data">
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                    @click="Edit(data.item)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>

  <!-- attendance card -->
  <b-row align-h="center" v-else-if="dataLoading">
    <b-spinner
      class="m-5"
      style="width: 4rem; height: 4rem"
      type="grow"
      variant="primary"
      label="Spinning"
    ></b-spinner>
  </b-row>
  <div v-else>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pt-1 pb-1">
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Barcode</h4>
            <b-form-checkbox
              v-model="settings.attendanceMode"
              switch
              value="barcode"
              unchecked-value=""
            />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Notification on arrival</h4>
            <b-form-checkbox v-model="settings.notifyArrival" switch />
          </b-col>
          <!-- <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Notification on departure</h4>
            <b-form-checkbox v-model="settings.notifyDeparture" switch />
          </b-col> -->
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Show dues</h4>
            <b-form-checkbox v-model="settings.showDues" switch />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Show recent attendance</h4>
            <b-form-checkbox v-model="settings.showRecent" switch />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Auto hide</h4>
            <b-form-checkbox v-model="settings.autoHide" switch />
          </b-col>
          <b-col v-if="settings.autoHide" md="12">
            <h4 class="mr-1">
              Hide After <span style="font-size: 14px">(seconds)</span>
            </h4>
            <b-form-input
              id="mc-first-name"
              type="number"
              placeholder=""
              ref="autosec"
              v-model="settings.hideAfterSeconds"
            />
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              class="mr-1 fullWidth"
              @click="saveSettings()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-row align-h="center">
      <b-col v-if="studentLoading" md="4">
        <b-card class="text-center p-1" style="position: relative">
          <b-spinner
            class="m-5"
            style="width: 4rem; height: 4rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-card>
      </b-col>
      <b-col lg="4" md="5" v-else>
        <b-card class="text-center p-1" style="position: relative">
          <feather-icon
            class="cursor-pointer"
            style="position: absolute; right: 15px; top: 15px"
            icon="SettingsIcon"
            size="22"
            v-b-tooltip.hover.right
            title="Settings"
            @click="showSettings()"
          />
          <b-img
            v-if="
              currentStd.std.picture == '' || currentStd.std.picture == null
            "
            class="round mb-1"
            src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
            style="
              width: 100px;
              height: 100px;
              object-fit: cover;
              border-radius: 50%;
            "
            :class="{ 'set-picSize': picObj && picObj.valueBool }"
          />
          <b-img
            v-else
            class="round mb-1"
            :src="currentStd.std.picture"
            style="
              width: 100px;
              height: 100px;
              object-fit: cover;
              border-radius: 50%;
            "
            :class="{ 'set-picSize': picObj && picObj.valueBool }"
          />
          <h3 v-if="currentStd.std.name" class="mb-1">
            {{ currentStd.std.name }}
          </h3>
          <h3 v-else class="mb-1">-</h3>
          <h4 v-if="currentStd.std.cls" style="margin-bottom: 0.5rem">
            {{ currentStd.std.cls }}
          </h4>
          <h4 v-else style="margin-bottom: 0.5rem">-</h4>
          <h4 style="font-weight: 400; margin-bottom: 0.5rem">
            GR #
            <span v-if="currentStd.std.grNo">{{ currentStd.std.grNo }}</span>
            <span v-else>-</span>
          </h4>
          <h4
            v-if="settings.showDues"
            style="font-weight: 400; margin-bottom: 0.5rem"
          >
            Dues: <span v-if="currentStd.dues">{{ currentStd.dues }}</span>
            <span v-else>-</span>
          </h4>
          <h1 v-if="currentStd.time" class="mt-1">
            {{
              new Intl.DateTimeFormat("en-US", {
                hour: "numeric",
                minute: "numeric",
              }).format(new Date(currentStd.time))
            }}
          </h1>

          <!-- <h4 class="text-danger font-weight-bolder">dues</h4> -->
          <b-row align-h="center">
            <b-form-input
              class="mt-1"
              style="width: 80%"
              id="mc-first-name"
              placeholder="Scan here"
              ref="name"
              autofocus
              v-model="studentID"
              @keyup.enter="GetStudent()"
            />
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col lg="4" md="5">
        <b-button variant="primary" block @click="openDetails()">
          Show Details
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="this.settings.showRecent && this.filteredItems.length > 0">
      <b-col md="12">
        <h3>Recent Attendace</h3>
      </b-col>
      <b-col lg="3" md="4" v-for="card in filteredItems" :key="card">
        <b-card>
          <b-row>
            <b-col md="3">
              <b-avatar
                v-if="card.std.picture == '' || card.std.picture == null"
                size="48"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
              />
              <b-avatar v-else size="48" :src="card.std.picture" />
            </b-col>
            <b-col md="9">
              <h5>{{ card.std.name }}</h5>
              <h5>
                {{ card.std.cls }}
                <span class="ml-50">GR# {{ card.std.grNo }}</span>
              </h5>
              <h5>
                {{
                  new Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(card.time))
                }}
                <span v-if="settings.showDues" class="ml-50"
                  >Dues: {{ card.dues }}</span
                >
              </h5>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BAvatar,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BModal,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BMedia,
    BMediaAside,
    BCard,
    BRow,
    BImg,
    BAvatar,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BModal,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadClasses();
      this.LoadSettings();
      this.loadSessions();
      this.LoadSubjects();
      this.loadOther();
    }
    // this.LoadClasses();
    // this.LoadSettings();
    // this.loadSessions();
    // this.LoadSubjects();
    // this.loadOther();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filteredItems: function () {
      return this.items.slice(0, 12);
    },
    filteredAtt() {
      return this.gridData.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.grNo.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      rights: {},
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        // mode: "range",
      },
      repDate: "",
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      myDepartments: [],
      request: false,
      sheetLoading: false,
      attendance: {
        picture: null,
        obj: {
          status: null,
        },
      },
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Balance",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      studentLoading: false,
      visibility: false,
      visibility2: false,
      visibility3: false,
      visibility4: false,
      logoloading: "",
      searchQuery: "",
      sortOptions: [
        { text: "Name", value: "name" },
        { text: "GR#", value: "gr" },
      ],
      sortBy: this.$store.state.attSort,
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      report: {
        type: "",
        dtFrom: new Date().toJSON().split("T")[0],
        // dtTo: null,
        clsIDs: [],
        secIDs: [],
      },
      editAtt: false,
      showreport: false,
      rangeDate: null,
      types: [
        { name: "Student", value: "student" },
        { name: "Staff", value: "staff" },
      ],
      statusOptions: [
        { name: "All", value: "" },
        { name: "Present", value: "P" },
        { name: "Absent", value: "A" },
        { name: "Leave", value: "L" },
        { name: "Late", value: "Late" },
      ],
      smartOptions: [
        { name: "Absent", value: "A" },
        { name: "Leave", value: "L" },
        { name: "Late", value: "Late" },
      ],
      showClasses: false,
      classes: [],
      classesOptions: [{ name: "All Classes", id: 0 }],
      admittedSections: [{ section: "All Sections", id: 0 }],

      classID: [0],
      secID: [0],
      subID: 0,
      tID: 0,
      gridData: [],
      showGrid: false,
      gridLoading: false,
      fields: [
        // { label: "profile", key: "profile" },
        { label: "gr no", key: "grNo" },
        { label: "name", key: "name" },
        { label: "Father name", key: "father_name" },
        { label: "class", key: "cls" },
        // { label: "date", key: "date" },
        { label: "status", key: "status" },
        // { key: "actions", label: "actions" },
      ],

      errors: {
        status: false,
      },

      index: null,
      items: [],
      accountOptions: [
        // {
        //   id: 1,
        //   code: 999,
        // },
      ],

      secText: "Section",
      classText: "Class",
      selected: null,
      myObj: {
        picture: null,
        cls: null,
        grNo: null,
        name: null,
      },
      currentStd: {
        std: {
          picture: null,
          cls: null,
          grNo: null,
          name: null,
        },
      },
      settings: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        notifyArrival: false,
        notifyDeparture: false,
        showDues: false,
        showRecent: false,
        autoHide: false,
        hideAfterSeconds: 5,
        attendanceMode: "",
      },
      msgObj: {
        dt: "",
        clsIDs: [0],
        secIDs: [0],
        status: "",
      },
      msgSecOptions: [{ section: "All Sections", id: 0 }],
      msgClass: [0],
      msgSection: [0],

      repObj: {
        type: "",
        dtFrom: "",
        clsIDs: [0],
        secIDs: [0],
        status: [],
      },
      repSecOptions: [{ section: "All Sections", id: 0 }],
      repClass: [0],
      repSection: [0],

      detailObj: {},
      timeout: 10000,
      studentID: null,
      saving: false,

      smartObj: {
        status: "",
        dt: new Date(),
        gr: "",
      },
      sessions: [],
      sessionID: 0,
      subjects: [],
      staffList: [],
      picObj: null,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    sortData() {
      this.$store.commit("setAttSort", this.sortBy);

      if (this.sortBy == "gr") {
        this.gridData = this.gridData.sort((a, b) =>
          a.grNo
            .toLowerCase()
            .localeCompare(b.grNo.toLowerCase(), "en", { numeric: true })
        );
      } else {
        this.gridData = this.gridData.sort((a, b) =>
          a.name
            .toLowerCase()
            .localeCompare(b.name.toLowerCase(), "en", { numeric: true })
        );
      }
    },

    async loadOther() {
      var obj = {
        url:
          this.$store.state.domain +
          "Settings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log(res);
      this.picObj = res.find((el) => el.key == "picture_size");
      // console.log(this.picObj);
    },

    async loadSessions() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sessions = await this.get(obj);
      this.sessionID = this.sessions.find((el) => el.isCurrentSession).id;
    },
    changeAtt(ind, val) {
      this.filteredAtt[ind].obj.status = val;
    },
    async save() {
      if (this.gridData.length != 0) {
        this.saving = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SaveWebCoaching?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.gridData,
          message: "Attendance saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) {
          this.loadgrid();
        }
      }
    },
    openDetails() {
      this.showreport = true;
      this.report.type = "student";
      this.showClasses = true;
    },
    setType(val) {
      this.report.type = val;
      if (this.report.type == "student") this.showClasses = true;
      else this.showClasses = false;
    },

    setTime() {
      console.log(this.timePicker);
      console.log(this.datepickr);
    },

    openMessage() {
      this.msgObj = {
        dt: "",
        clsIDs: [0],
        secIDs: [0],
        status: "P",
      };
      this.visibility2 = true;

      var elem = this.$refs["msg_class"];
      elem.state = undefined;
      // var elem = this.$refs["msg_section"];
      // elem.state = undefined;
      var elem = this.$refs["msg_date"];
      elem.state = undefined;
    },

    async saveMessage() {
      // console.log(this.msgObj);
      if (
        this.msgClass.length == 0 ||
        this.msgSection.length == 0 ||
        this.msgObj.dt == ""
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.msgClass[0] !== 0) {
          this.msgObj.clsIDs = this.msgClass;
        } else {
          this.msgObj.clsIDs = [];
        }
        if (this.msgSection[0] !== 0) {
          //console.log(this.secID);
          this.msgObj.secIDs = this.msgSection;
        } else {
          this.msgObj.secIDs = [];
        }
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SendMessage?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.msgObj,
          message: "Message send successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility2 = false;
        }
      }
    },
    FillMsgSections() {
      // console.log(this.msgClass);
      if (this.msgClass[0] == 0 && this.msgClass.length !== 1) {
        this.msgClass = this.msgClass.filter((e) => e !== 0);
        // console.log(this.msgClass);
        this.classes.forEach((elem) => {
          if (this.msgClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSecOptions.push(el));
            } else {
              // console.log("null");
              this.msgSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.msgClass[this.msgClass.length - 1] !== 0 &&
        this.msgClass.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.msgClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSecOptions.push(el));
            } else {
              // console.log("null");
              this.msgSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.msgClass[this.msgClass.length - 1] == 0) {
        this.msgClass = [0];
        // console.log(this.msgClass);
        this.msgSecOptions = [{ section: "All Sections", id: 0 }];
        this.msgSection = [0];
      } else {
        this.msgSecOptions = [{ section: "All Sections", id: 0 }];
        this.msgSection = [0];
      }
      // this.CheckClass();
    },
    checkMsgSections() {
      // console.log(this.msgSection);
      if (this.msgSection[0] == 0 && this.msgSection.length !== 1) {
        this.msgSection = this.msgSection.filter((e) => e !== 0);
        // console.log(this.msgSection);
      } else if (this.msgSection[this.msgSection.length - 1] == 0) {
        this.msgSection = [0];
        // console.log(this.msgSection);
      }
    },

    FillSections() {
      //   if (this.classID.includes(0) && this.classID.length == 2) {
      //     this.classID = this.classID.filter((e) => e !== 0);
      //     console.log(this.classID);
      //   }

      // console.log(this.classID);
      if (this.classID[0] == 0 && this.classID.length !== 1) {
        this.classID = this.classID.filter((e) => e !== 0);
        // console.log(this.classID);
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.classID[this.classID.length - 1] !== 0 &&
        this.classID.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.classID.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.admittedSections.push(el));
            } else {
              // console.log("null");
              this.admittedSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.classID[this.classID.length - 1] == 0) {
        this.classID = [0];
        // console.log(this.classID);
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      } else {
        this.admittedSections = [{ section: "All Sections", id: 0 }];
        this.secID = [0];
      }
      // this.CheckClass();
    },
    checkSections() {
      // console.log(this.secID);
      if (this.secID[0] == 0 && this.secID.length !== 1) {
        this.secID = this.secID.filter((e) => e !== 0);
        // console.log(this.secID);
      } else if (this.secID[this.secID.length - 1] == 0) {
        this.secID = [0];
        // console.log(this.secID);
      }
      // this.CheckSec();
    },
    CheckSec() {
      var elem = this.$refs["section"];
      if (this.secID.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    FillRepSections() {
      // console.log(this.repClass);
      if (this.repClass[0] == 0 && this.repClass.length !== 1) {
        this.repClass = this.repClass.filter((e) => e !== 0);
        // console.log(this.repClass);
        this.classes.forEach((elem) => {
          if (this.repClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.repSecOptions.push(el));
            } else {
              // console.log("null");
              this.repSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.repClass[this.repClass.length - 1] !== 0 &&
        this.repClass.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.repClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.repSecOptions.push(el));
            } else {
              // console.log("null");
              this.repSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.repClass[this.repClass.length - 1] == 0) {
        this.repClass = [0];
        // console.log(this.repClass);
        this.repSecOptions = [{ section: "All Sections", id: 0 }];
        this.repSection = [0];
      } else {
        this.repSecOptions = [{ section: "All Sections", id: 0 }];
        this.repSection = [0];
      }
      // this.CheckClass();
    },
    checkRepSections() {
      // console.log(this.repSection);
      if (this.repSection[0] == 0 && this.repSection.length !== 1) {
        this.repSection = this.repSection.filter((e) => e !== 0);
        // console.log(this.repSection);
      } else if (this.repSection[this.repSection.length - 1] == 0) {
        this.repSection = [0];
        // console.log(this.repSection);
      }
    },
    setRepDate(sd, dateStr) {
      // console.log(sd, dateStr);
      if (dateStr) {
        if (sd.length > 1) {
          let date = dateStr.split(" to ");
          if (date.length == 1) {
            this.repObj.dtFrom = date[0];
            this.repObj.dtTo = date[0];
          } else {
            this.repObj.dtFrom = date[0];
            this.repObj.dtTo = date[1];
          }
        } else {
          this.repObj.dtFrom = dateStr;
          this.repObj.dtTo = null;
        }
      } else {
        this.repObj.dtFrom = null;
        this.repObj.dtTo = null;
      }
      // console.log(this.repObj);
    },
    setStatus() {
      if (this.repObj.status[this.repObj.status.length - 1] == "") {
        this.repObj.status = [""];
      } else {
        this.repObj.status = this.repObj.status.filter((el) => el != "");
      }
      // console.log(this.repObj.status);
    },
    openReport() {
      const fd = new Date();
      const firstDate = `${fd.getFullYear()}-${(fd.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-01`;

      const ld = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
      const lastDate = `${ld.getFullYear()}-${(ld.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ld.getDate().toString().padStart(2, "0")}`;

      // console.log(firstDate, lastDate);
      this.repObj = {
        type: "",
        dtFrom: firstDate,
        dtTo: lastDate,
        clsIDs: [0],
        secIDs: [0],
        status: [""],
      };
      this.repDate = `${firstDate} to ${lastDate}`;

      this.visibility3 = true;

      var elem = this.$refs["rep_class"];
      elem.state = undefined;
      // var elem = this.$refs["rep_section"];
      // elem.state = undefined;
      var elem = this.$refs["rep_date"];
      elem.state = undefined;
    },

    async getReport() {
      // console.log(this.repObj);
      if (
        this.repClass.length == 0 ||
        this.repSection.length == 0 ||
        this.repObj.status.length == 0 ||
        !this.repObj.dtFrom ||
        !this.repObj.dtTo
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.repClass[0] !== 0) {
          this.repObj.clsIDs = this.repClass;
        } else {
          this.repObj.clsIDs = "";
        }
        if (this.repSection[0] !== 0) {
          this.repObj.secIDs = this.repSection;
        } else {
          this.repObj.secIDs = "";
        }
        let status = "";
        if (this.repObj.status[0] !== "") {
          status = this.repObj.status;
        }
        this.request = true;

        var url =
          `https://${this.reportDomain}.myskool.app/Attendance/Show?type=student&dtFrom=` +
          this.repObj.dtFrom +
          "&dtTo=" +
          this.repObj.dtTo +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&cls=" +
          this.repObj.clsIDs +
          "&sec=" +
          this.repObj.secIDs +
          "&status=" +
          status;

        window.open(url, "_blank");
        this.request = false;
        // if (status) {
        //   this.visibility3 = false;
        // }
      }
    },
    async getSheet() {
      if (this.repClass.length == 0 || this.repSection.length == 0) {
        return this.$bvToast.toast("Please select class and section", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.repClass[0] !== 0) {
          this.repObj.clsIDs = this.repClass;
        } else {
          this.repObj.clsIDs = "";
        }
        if (this.repSection[0] !== 0) {
          this.repObj.secIDs = this.repSection;
        } else {
          this.repObj.secIDs = "";
        }

        this.sheetLoading = true;
        if (this.$store.state.currentBranch.organizationType == "coaching") {
          var url =
            `https://${this.reportDomain}.myskool.app/List/Show?type=attendance&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&cls=" +
            this.repObj.clsIDs +
            "&sec=" +
            this.repObj.secIDs +
            "&session=" +
            this.sessionID +
            "&status=&mob=false";

          window.open(url, "_blank");
        } else {
          var url =
            `https://${this.reportDomain}.myskool.app/List/Show?type=attendance&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&cls=" +
            this.repObj.clsIDs +
            "&sec=" +
            this.repObj.secIDs +
            "&status=&mob=false";

          window.open(url, "_blank");
        }
        this.sheetLoading = false;
      }
    },
    async loadgrid() {
      if (this.subID == 0) {
        this.$bvToast.toast("Please select the subject", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else if (this.tID == 0) {
        this.$bvToast.toast("Please select the teacher", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.gridLoading = true;
        this.showGrid = true;
        if (this.classID[0] !== 0) {
          this.report.clsIDs = this.classID;
        } else {
          this.report.clsIDs = [];
        }
        if (this.secID[0] !== 0) {
          //console.log(this.secID);
          this.report.secIDs = this.secID;
        } else {
          this.report.secIDs = [];
        }
        // console.log(this.report);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/LoadAttendanceGridCoaching?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&subID=" +
            this.subID +
            "&tID=" +
            this.tID,
          body: this.report,
          attendance: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.gridData = status;
          this.sortData();
        }
        this.gridLoading = false;
      }
    },
    async Edit(item) {
      this.attendance = item;
      // console.log(this.attendance);
      this.editAtt = true;
      this.datepickr = this.attendance.date.split("T")[0];
      this.timePicker = this.attendance.date.split("T")[1];

      var elem = this.$refs["account"];
      elem.state = undefined;
      var elem = this.$refs["balance"];
      elem.state = undefined;
    },
    async GetStudent() {
      if (this.studentID.length !== 0) {
        this.studentLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "Attendance/LoadStudentData?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&gr=" +
            this.studentID +
            "&userID=" +
            this.$store.state.userData.userID,
          token: this.$store.state.userData.token,
        };
        this.currentStd = await this.get(obj);
        // console.log("res:", this.currentStd);
        this.studentLoading = false;

        if (this.currentStd == "No data found.") {
          this.currentStd = {
            std: {
              picture: null,
              cls: null,
              grNo: null,
              name: null,
            },
            dues: null,
          };
          return this.$bvToast.toast("No student found.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else if (this.currentStd == "Student's profile is not active.") {
          this.currentStd = {
            std: {
              picture: null,
              cls: null,
              grNo: null,
              name: null,
            },
            dues: null,
          };
          return this.$bvToast.toast("Student's profile is not active.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          // this.myObj = this.currentStd.std;
          // console.log("obj:", this.myObj);

          this.items.unshift(this.currentStd);
          // console.log(this.items);
        }
        this.studentID = "";
        if (this.settings.autoHide) {
          setTimeout(() => {
            this.currentStd = {
              std: {
                picture: null,
                cls: null,
                grNo: null,
                name: null,
              },
              dues: null,
            };
            this.studentID = "";
          }, this.settings.hideAfterSeconds * 1000);
        }
      }
    },
    CheckAccount() {
      var elem = this.$refs["account"];
      if (this.myObj.account == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckBalance() {
      var elem = this.$refs["balance"];
      var re = /^[0-9]+$/;
      if (re.test(this.myObj.balance) && this.myObj.balance !== 0) {
        this.myObj.balance = parseInt(this.myObj.balance);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    hideAtt() {
      this.editAtt = false;
    },

    showSmart() {
      this.visibility4 = true;
    },
    async saveSmart() {
      if (
        this.smartObj.gr.trim() == "" ||
        this.smartObj.date == "" ||
        this.smartObj.status == ""
      ) {
        this.$bvToast.toast("Please enter the details properly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.smartObj.gr = this.smartObj.gr.replace(/\n|,/g, " ");
        // console.log(this.smartObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Attendance/SmartAttendance?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.smartObj,
          message: "Attendance saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility4 = false;
        }
      }
    },

    showSettings() {
      this.visibility = true;
    },
    async LoadSettings() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "AttendanceSettings/LoadData?campusID=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      this.dataLoading = false;
      if (result == null) {
        this.settings = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          notifyArrival: false,
          notifyDeparture: false,
          showDues: false,
          showRecent: false,
          autoHide: false,
          hideAfterSeconds: 5,
          attendanceMode: "",
        };
      } else {
        this.settings = result;
      }
      // console.log("set", this.settings);
    },
    async loadStaff() {
      this.tID = 0;
      this.staffList = [];

      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "Subjects/LoadTeacherBySubjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&subjectID=" +
          this.subID,
        token: this.$store.state.userData.token,
      });
      this.staffList = myitem;
    },
    async LoadSubjects() {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj);

      // console.log("subject", this.items);
    },
    async LoadClasses() {
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      // console.log("class", this.classes);
      this.classes.forEach((el) => {
        this.classesOptions.push(el.cls);
      });
    },
    async LoadSections() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sections?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sections = await this.get(obj);
      // console.log("sections", this.sections);
    },
    async saveSettings() {
      this.request = true;
      let x = parseInt(this.settings.hideAfterSeconds);
      this.settings.hideAfterSeconds = !x ? 5 : x;

      var status = await this.post({
        url:
          this.$store.state.domain +
          "AttendanceSettings/Save?db=" +
          this.$store.state.userData.db,
        body: this.settings,
        message: "Settings saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.visibility = false;
        this.LoadSettings();
      }
    },
    async saveAttendance() {
      this.request = true;
      // this.attendance.obj.date = this.datepickr + this.timePicker;
      this.attendance.obj.date = new Date().toJSON();
      var status = await this.put({
        url:
          this.$store.state.domain +
          "Attendance/id=" +
          this.attendance.obj.id +
          "?db=" +
          this.$store.state.userData.db,
        body: this.attendance.obj,
        message: "Attendance updated successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.editAtt = false;
        this.loadgrid();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card-body {
  padding: 0.85rem;
}
@keyframes myfade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.set-picSize {
  width: 180px !important;
  height: 180px !important;
}
</style>
